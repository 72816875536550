<template>
  <div>
    <!-- table-->
    <b-row>
      <b-col class="">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
          <b-table
              id="table"
              ref="staff_table"
              :fields="fields"
              :items="getStaff"
              :current-page="currentPage"
              :per-page="pagination.perPage"
              hover
              class="position-relative mobile_table_css"
              empty-text="No matching records found"
              primary-key="id"
              responsive
              show-empty
          >
          </b-table>
        </b-overlay>
      </b-col>
    </b-row>

    <!--                pagination-->
    <div class="mx-1 mb-2">
      <b-row>

        <b-col
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
            cols="12"
            sm="6"
        >
          <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
            cols="12"
            sm="6"
        >

          <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import AddProgrammeSideBar from '@/views/programmes/sidebars/AddProgrammeSideBar'

import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import LocationAPI from '@/apis/modules/locations'

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    AddProgrammeSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin],

  data: function () {
    return {
      currentPage: 1,
      tableLoading: false,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'role',
        },
        {
          key: 'practice',
        },

        {
          key: 'telephone',
        },
        {
          key: 'email',
        },
        // {
        //   key: 'total_hr_worked',
        //   sortable: true
        // },
        // {
        //   key: 'total_earned',
        //   sortable: true
        // }
      ],
    }
  },
  methods: {
    dataRefresh(){
      this.$refs.staff_table.refresh()
    },
    async getStaff(){
      try{
        this.tableLoading = true
        const Response = await LocationAPI.getLocationStaffs(this.$route.params.id, this.currentPage, 15);
        const dataArray = Response.data.data.map((x) => ({
          name: x.user.first_name + ' '+ x.user.last_name,
          telephone: x.user.phone_number ? x.user.phone_number : '-',
          role: x.location_job_role.name ? x.location_job_role.name : '-',
          email: x.user.email,
          practice:x.practice.name
        }))

        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        this.tableLoading = false

        return dataArray

      }catch{
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async tableRefresh(){
      this.$refs.staff_table.refresh()

    }
  },
  mounted(){
    this.getStaff();
  }
}
</script>

<style scoped>

</style>

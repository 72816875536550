<template>
  <div>
<!--    <div class="min-vh-100">-->
    <b-alert
        :show="true"
        class="mb-5"
        variant="primary "
    >
      <div class="alert-body text-dark py-2 ">
        <feather-icon
            class="mr-50 "
            icon="InfoIcon"
        />
        All payments should be made to the following account: <span class="font-weight-bolder ml-1">Bank Name: Bank of England</span>
        <span class="font-weight-bolder ml-1">Account Number: 56765788</span>
        <span class="font-weight-bolder ml-1">Sort Code: 12-34-56</span>

      </div>
    </b-alert>

    <div class="d-flex justify-content-center my-5">
      <div class="align-self-center bg-light-info rounded-circle p-2 my-5 ">
      <feather-icon stroke-width="0.3"
          class="text-dark"
          icon="FileTextIcon"
          size="160"
      />
        </div>
    </div>
  </div>
<!--  </div>-->
</template>

<script>

import {
  BAlert,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'clinical-hours',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BAlert,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },

  data: function () {
    return {
      currentPage: 1,
      isAddNewProgrammeSidebarActive: false,

      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'role',
          sortable: true
        },
        {
          key: 'number',
          sortable: true
        },
        {
          key: 'telephone',
          sortable: true
        },
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'total_hr_worked',
          sortable: true
        },
        {
          key: 'total_earned',
          sortable: true
        }
      ],
      items: [
        {
          'name': 'Sandoval Kim',
          'role': 'QUIZKA',
          'number': '15',
          'telephone': '+1 (876) 511-2396',
          'email': 'sandovalkim@quizka.com',
          'total_hr_worked': '$3,428.56',
          'total_earned': '$3,428.56'
        },
        {
          'name': 'Mildred Mcleod',
          'role': 'ANDRYX',
          'number': '15',
          'telephone': '+1 (894) 516-2935',
          'email': 'mildredmcleod@andryx.com',
          'total_hr_worked': '$3,428.56',
          'total_earned': '$3,428.56'
        },
        {
          'name': 'Scott Garner',
          'role': 'INTRADISK',
          'number': '15',
          'telephone': '+1 (820) 556-2808',
          'email': 'scottgarner@intradisk.com',
          'total_hr_worked': '$3,428.56',
          'total_earned': '$3,428.56'
        },
        {
          'name': 'Minerva Herman',
          'role': 'POLARIUM',
          'number': '15',
          'telephone': '+1 (995) 486-2958',
          'email': 'minervaherman@polarium.com',
          'total_hr_worked': '$3,428.56',
          'total_earned': '$3,428.56'
        },
        {
          'name': 'Faith Hutchinson',
          'role': 'INVENTURE',
          'number': '15',
          'telephone': '+1 (849) 586-2381',
          'email': 'faithhutchinson@inventure.com',
          'total_hr_worked': '$3,428.56',
          'total_earned': '$3,428.56'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <div class="d-flex flex-row mb-2 flex-wrap pr-view">
        <!-- <pre>{{locationDetails}}</pre>-->
        <b-button
            @click="$router.back()"
            class="btn-icon bg-white shadow-sm font-weight-bold mr-2 align-self-baseline"
            variant="white">
          <feather-icon icon="ArrowLeftIcon" size="20"/>
        </b-button>
        <div>

            <h2 class="font-weight-bold">{{locationDetails.location.name}}</h2>
            <span class="mr-1">
                        <feather-icon icon="MapPinIcon"/>
                        {{locationDetails.location.address + ', ' + locationDetails.location.city + ' '+locationDetails.location.postal_code}}
            </span>
            <span class="mr-1">
                        <feather-icon icon="PhoneIcon"/>
                        {{locationDetails.location.telephone ? locationDetails.location.telephone : 'N/A'}}
            </span>
            <span class="mr-1">
                        <feather-icon icon="MailIcon"/>
                      {{locationDetails.location.email ? locationDetails.location.email : 'N/A'}}
            </span>
            <span class="mr-1">
                        <feather-icon icon="CreditCardIcon"/>
                      £{{locationDetails.location.service_charge}}/hour
            </span>

        </div>
        <div class="ml-lg-auto">
          <b-button
              class="btn-icon bg-white shadow-sm font-weight-bold mr-1 text-primary shadow-lg"
              v-b-tooltip.hover.top="'Edit'"
              @click="toggleSideBars"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              border-colour="#1B9AAA"
              variant="lighten-2"
          >
            <feather-icon icon="EditIcon" size="20"/>
          </b-button>
          <b-button
              class="btn-icon bg-white shadow-sm font-weight-bold text-primary shadow-lg"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              border-colour="#1B9AAA"
              variant="lighten-2"
              @click="confirmButtonColor"
              >
            <feather-icon icon="TrashIcon" size="20"/>
          </b-button>
        </div>
      </div>
    </b-overlay>
    <b-card class="mb-0" no-body>
      <div class="m-2">
        <b-tabs>
          <b-tab title="Staff" lazy>
            <staff ref="staffTable"></staff>
          </b-tab>
          <b-tab title="Payments" lazy>
            <Payments></Payments>
          </b-tab>
        </b-tabs>

      </div>
    </b-card>

  <EditLocationSideBar
      ref="EditLocationSideBar"
      :is-edit-location-sidebar-active.sync="isEditLocationSidebarActive"
      @toggleSideBars="toggleSideBars()"
      @dataRefresh="dataRefresh()"/>

  </div>
</template>

<script>
import Staff from '@/views/locations/list/view/staff'
import Payments from '@/views/locations/list/view/payments'
import locations from '@/apis/modules/locations'
import Ripple from 'vue-ripple-directive'

import EditLocationSideBar from '@/views/locations/sidebars/EditLocationsideBar.vue'

import MomentMixin from "@/mixins/MomentMixin";

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BTabs,
    BTab,
    BOverlay,
    Staff,
    Payments,
    vSelect,
    EditLocationSideBar
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },

  mixins: [MomentMixin],

  data: function() {
    return {
      isEditLocationSidebarActive: false,
      loading:false,
      locationDetails: {}
    }
  },

  methods:{

    toggleSideBars() {
      this.isEditLocationSidebarActive = !this.isEditLocationSidebarActive
    },

    dataRefresh(){
      //this.$refs.table.refresh()
      this.getLocationDetails()
      this.isEditLocationSidebarActive = false
      this.$refs.staffTable.tableRefresh()
    },



    async deleteShift() {


      this.$swal({
        title: 'Are you sure you want to delete this shift?',
        text: "This action cannot be reverted.",
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: "No, go back",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          try {
            await shift.deleteEvent(this.data.Id)
            this.$parent.$parent.$parent.getShiftData()
          }catch (error){
            this.convertAndNotifyError(error)
          }

        }
      })
    },


    async getLocationDetails() {
      try{
        this.loading = true
        const response=await locations.getDetailsOfLocation(this.$route.params.id)
        this.locationDetails=response.data.data

        //console.log(this.locationDetails)
        this.loading = false
      }catch(error){
        this.convertAndNotifyError(error)
        this.loading = false
      }

    }
  },

  mounted () {
    this.getLocationDetails()
  },
}
</script>

<style scoped>

</style>

<template>
  <b-sidebar
      id="edit-location-sidebar"
      :visible="isEditLocationSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-edit-location-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <validation-observer
          ref="CreateLocationForm"
      >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Edit Location
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="cancel()"
        />

      </div>

      <!-- BODY -->
      <b-alert
          :show="true"
          class="mb-0 mt-2 rounded ml-2 mr-2"
          variant="info"
      >


            <div class="row breadcrumbs-top">

              <div class="breadcrumb-wrapper text-dark">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">

                <b-badge class="mr-1 ml-1 " style="padding:0.5rem" :variant="current_tab == 'General-Details'? 'primary' : 'light-dark'">
                <span :class="current_tab == 'General-Details'? 'text-white' : 'text-dark'">1</span>
               </b-badge>
               <span @click.prevent="changeTab('General-Details')" :class="current_tab == 'General-Details'? 'text-primary' : 'text-dark'" style="cursor: pointer;"> General Details </span>

                  <li class="breadcrumb-item active">
                  <span aria-current="location">

                <b-badge class="mr-1 " style="padding:0.5rem" :variant="current_tab == 'Staff'? 'primary' : 'light-dark'">
               <span :class="current_tab == 'Staff'? 'text-white' : 'text-dark'">2</span>
                </b-badge>
                  <span @click.prevent="changeTab('Staff')" :class="current_tab == 'Staff'? 'text-primary' : 'text-dark'" style="cursor: pointer;">Staff</span>
<!--                  <span @click.prevent="changeTab('Staff')">Staff</span>-->


                </span>
                </li>
                  </ol>
              </div>
          </div>

      </b-alert>
    <!--  General details -->
    <b-overlay :show="loading" rounded="sm">
        <div  class="m-2" v-show="current_tab === 'General-Details'">
            <b-card>
            <b-row>
                <b-col cols="12">
                <b-form-group
                    label="Name"
                    label-for="v-name"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                    >
                    <b-form-input
                        id="v-name"
                        placeholder="e.g.General Medical Practice"
                        v-model="location.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>

                <b-col cols="12">
                <b-form-group
                    label="Address"
                    label-for="v-address"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Address"
                        rules="required|min_address"
                    >
                    <b-form-input
                        id="v-address"
                        placeholder="e.g.Guilt Street 15"
                        v-model="location.address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>

                <b-col md="6">
                <b-form-group
                    label="City"
                    label-for="city"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                    >
                    <b-form-input
                        id="city"
                        placeholder=""
                        v-model="location.city"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group
                    label="Postal Code"
                    label-for="postal-code"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Postal Code"
                        rules="required|postal_code"
                    >
                    <b-form-input
                        id="postal-code"
                        placeholder="e.g.PR8 8UW"
                        v-model="location.postal_code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>

                <b-col md="6">
                <b-form-group
                    label="Telephone (optional)"
                    label-for="telephone"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Telephone"
                        rules="tel|min_tel|max_tel"
                    >
                    <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                        <feather-icon icon="PhoneIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="telephone"
                        placeholder="xxx xxxx xxxx"
                        v-model="location.telephone"
                    />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group
                    label="Email (optional)"
                    label-for="email"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="emailTest"
                    >
                    <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="email"
                        placeholder="contact@mail.co.uk"
                        v-model="location.email"
                    />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>

                <b-col md="4">
                <b-form-group
                    label="Service charge per hour (optional)"
                    label-for="service-charge-per-hour"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Service Charge"
                        rules="numeric|max"
                    >
                    <b-input-group class="input-group-merge"
                                prepend="£">
                    <b-input-group-prepend is-text>
                        <feather-icon icon=""/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="service-charge-per-hour"
                        placeholder=""
                        v-model="location.service_charge"
                    />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>

            </b-row>



            <b-col cols="12">
                <b-row>
                <div class="bank-details">
                    Bank Details
                </div>

                <div class="optional-class">
                    Optional
                </div>
                </b-row>
            </b-col>

            <b-col cols="12" class="pl-0">
                <div class="mt-3">
                <b-row>
                    <b-col cols="12">
                    <b-form-group
                        label="Bank Name"
                        label-for="v-bank-name"
                    >
                        <b-form-input
                            id="v-bank-name"
                            placeholder="e.g.Bank of England"
                            v-model="bank_details.name"
                        />
                    </b-form-group>
                    </b-col>

                    <b-col md="6" v-if="bank_details.name">
                    <b-form-group
                        label="Account Number"
                        label-for="account-number"
                    >

                        <validation-provider
                            #default="{ errors }"
                            name="Account Number"
                            rules="required"
                        >
                        <b-form-input
                            id="account-number"
                            placeholder="xxxxxxxx"
                            v-model="bank_details.account_number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                    </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="bank_details.name">
                    <b-form-group
                        label="Sort Code"
                        label-for="sort-code"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Sort Code"
                            rules="required|min_sort_code|max_sort_code"
                        >
                        <b-form-input
                            id="sort-code"
                            placeholder="xx-xx-xx"
                            v-model="bank_details.sort_code"

                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                </b-row>

                <b-form-group class="mb-0 mt-3">
                    <b-button
                        class="btn-sm-block"
                        variant="primary"
                        :disabled="checkLocationDetails"
                        @click.prevent="changeTab('Staff')"
                    >

                    <span class="align-middle mb-3">Next</span>
                    <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50 text-light"

                    />
                    </b-button>
                </b-form-group>
                </div>
            </b-col>

            </b-card>
        </div>
    </b-overlay>
    <!--  Staff details -->
        <div class="m-2" v-show="current_tab === 'Staff'">
          <b-card>
            <b-overlay :show="staffLoading" rounded="sm">
              <b-col cols="12">
                <span>Add staff that will be working from this location.</span>
              </b-col>

              <b-col cols="12" md="12" class="pb-0 mt-1">
                <b-input-group class="input-group-merge mb-1">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon"/>
                  </b-input-group-prepend>
                  <b-form-input v-model="filters['search']" placeholder="Search"/>
                </b-input-group>
              </b-col>


              <span>{{ pcnStaffRecords }}</span>

              <b-form-checkbox
                  id="All"
                  name="All"
                  class="mt-2 mb-2"
                  @change.native="SelectOrUnselectAll($event)"
              >
                <span class="todo-title font-weight-bolder">Select All</span>
              </b-form-checkbox>

              <ul class="list-group">
                <li
                    v-for="(role) in pcnStaff"
                    class="todo-item  list-group-item py-1"
                >
<!--                  <validation-provider-->
<!--                      #default="{ errors }"-->
<!--                      name="Staff"-->
<!--                      rules="required"-->
<!--                  >-->
                    <b-form-checkbox
                        :value="role.name"
                        :id="role.name"
                        v-model="all"
                        @change.native="checkGroup(role, $event)"
                    >
                      <span class="todo-title font-weight-bolder" v-if="role.name">{{ role.name }}</span>
                    </b-form-checkbox>
                    <span
                        v-for="(person,index) in role.array"
                        class="todo-item list-group-item py-1"
                    >
                      <b-form-checkbox
                          :value="person"
                          :id="role.name+'_'+person.user_id"
                          v-model="checkedStaffMembers"
                          @change.native="getStaff(person,role, $event)"
                      >
                      <span>{{ person.user_name }}</span>
                      <span>{{ ' - ' + person.practice_name }}</span>
                      <b-badge v-if="person.is_locum == 1" class="mr-1 mb-1" style="padding:0.8rem" variant="light-info">
                      <span class="text-dark font-weight-700">L</span>
                      </b-badge>
                      </b-form-checkbox>
                    </span>
<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                  </validation-provider>-->
                </li>
              </ul>
            </b-overlay>
          </b-card>
        </div>

        <b-col cols-12 class="ml-2" v-if="current_tab === 'Staff'">
            <b-button

                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-1"
                type="submit"
                variant="primary"
                :disabled="checkLocationAndStaffDetails"
                @click="editLocationMethod()"
            >
                Save
            </b-button>

            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="cancel()"
            >
                <span class="text-secondary">
                Cancel
                </span>
            </b-button>
        </b-col>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {tasks} from './todoTO_BE_REMOVED'
import GeneralDetails from './GeneralDetails'
import Staff from './Staff'
import {
  BBadge,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BOverlay,
  BSidebar, BCard, BCol, BRow, BInputGroupPrepend,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {tel,min_tel, max_tel,emailTest, required,min_address,numeric,min,max,min_sort_code,max_sort_code,postal_code} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import locations from '@/apis/modules/locations'


import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import LocationAPI from "@/apis/modules/locations";

export default {

  components: {
    BCard,
    BCol,
    BRow,
    BInputGroupPrepend,
    GeneralDetails,
    Staff,
    BBadge,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isEditLocationSidebarActive',
    event: 'update:is-edit-location-sidebar-active'
  },
  props: {
    isEditLocationSidebarActive: {
      type: Boolean,
    },
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data () {
    return {
      all:[],
      required,
      staffLoading:false,
      loading:false,
      start_date: '',
      end_date: '',
      location: {
        name: '',
        address: '',
        city: '',
        postal_code: '',
        telephone: '',
        email: '',
        service_charge: ''
      },
      bank_details: {
        name: '',
        account_number: '',
        sort_code: ''
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      filters: {},
        filter: null,
        filterOn: [],
      pcnStaff: [],
      checkedStaffMembers: [],
      roles:[],
      selectedStaffPayload:[],

      detailsOfMember:[],

      tasks: tasks,
      practices: [],
      current_tab:'General-Details',
      isActive:true,
      pcnStaffRecords: ''
    }
  },
  computed: {
    checkLocationDetails() {
      if(this.location.name && this.location.address && this.location.city && this.location.postal_code ){
        return !this.isActive
      }else {
       return this.isActive === true
      }
    },
    checkLocationAndStaffDetails() {
      if(this.location.name && this.location.address && this.location.city && this.location.postal_code  && this.checkedStaffMembers.length != 0){
        return !this.isActive
      }else {
        return this.isActive === true
      }
    }
  },
  methods: {
    SelectOrUnselectAll(e){
        this.pcnStaff.forEach((role, index) => {
          role.array.forEach((val, i) => {
            if(e.target.checked === true) {
                if(!this.checkedStaffMembers.some(o => o.practice_id == val.practice_id && o.user_id == val.user_id)){
                  this.checkedStaffMembers.push(val)
                }
              document.getElementById(role.name).checked = true
            }else{
              this.checkedStaffMembers = []
              document.getElementById(role.name).checked = false
            }
          })
        })
    },

    toggle() {
      this.$emit('toggleSideBars')
    },

    cancel() {
      this.current_tab = 'General-Details'
      this.$emit('toggleSideBars')
      this.filters = []
    },
    changeTab(text) {
      this.current_tab = text
    },

    checkGroup (option, e) {
      this.pcnStaff.forEach((value, index) => {
        if (value.name === option.name && e.target.checked === true) {
          value.array.forEach((val, i) => {
            if(!this.checkedStaffMembers.some(o => o.practice_id == val.practice_id && o.user_id == val.user_id)){
              this.checkedStaffMembers.push(val)
            }
          })
        } else if (value.name === option.name && e.target.checked === false) {
          option.array.forEach((val, i) => {
            this.checkedStaffMembers.forEach((val2, i2) => {
              if (val.user_id === val2.user_id && val.practice_id === val2.practice_id) {
                const index = this.checkedStaffMembers.indexOf(val2)
                this.checkedStaffMembers.splice(index, 1)
              }
            })
          })
        }
        this.checkSelect()
      })
    },

    getStaff (record, role, e) {
      this.detailsOfMember.push(record.is_locum, record.user_id, record.practice_id)
      this.pcnStaff.forEach((value, index) => {
        if (value.name === role.name && e.target.checked === true) {
          if (value.array.every(r => this.checkedStaffMembers.includes(r))) {
            console.log('Found all of value array in checkedStaffMembers')
            document.getElementById(role.name).checked = true
          }
          else {
            console.log('Did not find all of value.array in selectedObjs')
          }
          this.checkSelect()
        } else if (value.name === role.name && e.target.checked === false) {
          if (!value.array.every(r => this.checkedStaffMembers.includes(r))) {
            document.getElementById(role.name).checked = false
            this.checkSelect()
          }
        }
        if(document.getElementById('All').checked === true){
          document.getElementById(role.name).checked = true
        }
      })
      this.showStaff()
    },

    async showStaff () {
      this.pcnStaff.forEach((value, index) => {
        let count = 0
        let valueLength = value.array.length
        value.array.forEach((value1, index1) => {
          this.checkedStaffMembers.forEach((value2, index2) => {
            if (value1.practice_id == value2.practice_id && value1.user_id == value2.user_id) {
              count = count + 1
            }
          })
        })
        if (count === valueLength) {
          this.all.push(value.name)
        }
      })
    },

    async getAllreadyStaff(){
      try{
        this.staffLoading = true
        const Response = await LocationAPI.getLocationStaffsWithoutPagination(this.$route.params.id);
        this.checkedStaffMembers = await Response.data.data.map((x) => ({
          user_name:x.user.first_name +' '+ x.user.last_name,
          user_id: x.user.id,
          practice_name:x.practice.name,
          practice_id: x.practice.id,
          is_locum: x.is_locum,
        }))
        this.staffLoading = false
      }catch(error){
        this.convertAndNotifyError(error)
        this.staffLoading = false
      }
    },
    async editLocationMethod(){
      if (await this.$refs.CreateLocationForm.validate()) {
        try {
          this.selectedStaffPayload = this.checkedStaffMembers.map((x) => ({
            user_id: x.user_id,
            practice_id: x.practice_id,
            is_locum: x.is_locum
          }))
          const payload = {
            location: this.location,
            staff: this.selectedStaffPayload
          }
          if(this.bank_details.name) {
            payload.bank_details = this.bank_details
          }
          const Response = await locations.editLocation(payload, this.$route.params.id)
          if(Response.status === 200) {
            this.showSuccessMessage('Location Edited Successfully')
            this.$emit('dataRefresh')
            this.ClearCreateLocationForm()
          }
          //this.$emit('CreateLocation')
        } catch (error) {
          this.convertAndNotifyError(error)
        }
      }
    },

    async getPcnStaffJobRoleWise () {
      try {
        this.staffLoading = true

        const response=await locations.getPcnStaffJobRoles(this.filterQuery)

        const test = Object.entries(response.data.data)
        this.pcnStaff =[]
        test.forEach((x)=>{
          let obj = {
            name:  x[0],
            array: x[1].map((x)=>({
              user_name:x.user.first_name +' ' +x.user.last_name,
              user_id:x.user.id,
              practice_name:x.practice.name,
              practice_id:x.practice.id,
              is_locum:x.is_locum

            }))
          }
          this.pcnStaff.push(obj)
        })
        this.pcnStaffRecords = (this.pcnStaff.length === 0 ?
            'Please connect to practice to show staff.': 'Please select staff.')
        this.staffLoading = false

      }catch (error){
      this.convertAndNotifyError(error)
        this.staffLoading = false

      }
    },
    getFormData() {
      return [this.staff]
    },

    filterQueryUpdate () {
      this.getPcnStaffJobRoleWise();
      this.setDetails();
    },
    async setDetails(){

      try{
        this.loading = true
        const Response = (await locations.getDetailsOfLocation(this.$route.params.id)).data.data
        this.location.name = Response.location.name
        this.location.address = Response.location.address
        this.location.city = Response.location.city
        this.location.email = Response.location.email
        this.location.postal_code = Response.location.postal_code
        this.location.service_charge = Response.location.service_charge
        this.location.telephone = Response.location.telephone
        this.bank_details.name = Response.bank_details[0].name
        this.bank_details.account_number = Response.bank_details[0].account_number
        this.bank_details.sort_code = Response.bank_details[0].sort_code
        this.loading = false
      }catch(error){
        this.convertAndNotifyError(error)
        this.loading = false
      }

    },
    ClearCreateLocationForm() {
      this.location.name = ''
      this.location.address = ''
      this.location.city = ''
      this.location.email = ''
      this.location.postal_code = ''
      this.location.service_charge = ''
      this.location.telephone = ''
      this.bank_details.name = ''
      this.bank_details.account_number = ''
      this.bank_details.sort_code = ''
      this.checkedStaffMembers = ''
    },

    async checkSelect(){
      let count = 0
      this.pcnStaff.forEach((value, index) => {
        value.array.forEach((val, i) => {
          count = count+1
        })
      })
      if(count ===  this.checkedStaffMembers.length){
        document.getElementById('All').checked = true
      }else{
        document.getElementById('All').checked = false
      }
    },

    async checkSelectAll(){
      let count = 0
      await this.getPcnStaffJobRoleWise()
      await this.getAllreadyStaff()
      this.pcnStaff.forEach((value, index) => {
        value.array.forEach((val, i) => {
          count = count+1
        })
      })
      if(count ===  this.checkedStaffMembers.length){
        document.getElementById('All').checked = true
      }else{
        document.getElementById('All').checked = false
      }
    }
  },
  watch: {
    async isEditLocationSidebarActive(val){
        if(val){
          await this.setDetails()
          await this.getPcnStaffJobRoleWise();
          await this.getAllreadyStaff()
          await this.showStaff()
        }
    }
  },
  async mounted() {
    await this.checkSelectAll()
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#edit-location-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";

</style>
<style>
.optional-class {
position: absolute;
height: 21px;
left: 437px;
right: 0px;
margin-top: 3px;
/*top: calc(50% - 21px/2 + 162px);*/

font-family: Mulish;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;

/* identical to box height, or 150% */
text-align: right;

/* $dark-grey */
color: #949494;
}

.bank-details {
position: absolute;
/*height: 20.19px;*/
left: 0%;
right: 77.73%;
/*top: calc(50% - 20.19px/2 + 161.59px);*/

font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 20px;

/* Dark */
color: #171822;
}

</style>
